import { ref } from 'vue';
import { getOrCreateProcedure } from 'o365-modules';

const modalInitialMatrix = ref(null);
const modalFinalMatrix = ref(null);

let reloadKey = ref(1);

// Workflow Step Procedures
const procCreateSJA = getOrCreateProcedure({ id: "procCreateSJA", procedureName: "astp_SJA_NewSJA" });
const procSJAWorkflowAction = getOrCreateProcedure({ id: "procSJAWorkflowAction", procedureName: "astp_SJA_NewWorkflowAction" });

// SJA Procedures
const procGetMatrix = getOrCreateProcedure({ id: "procGetMatrix", procedureName: "astp_SJA_GetMatrix" });
const procDeleteSJA = getOrCreateProcedure({ id: "procDeleteSJA", procedureName: "astp_SJA_DeleteSJA" });
const procRemoveSignature = getOrCreateProcedure({ id: "procRemoveSignature", procedureName: "astp_SJA_RemoveSignature" });
const procSignSJA = getOrCreateProcedure({ id: "procSignSJA", procedureName: "astp_SJA_Sign" });
const procAddResponsible = getOrCreateProcedure({ id: "procAddResponsible", procedureName: "astp_SJA_AddResponsible" });
const procDeleteTask = getOrCreateProcedure({ id: "procDeleteTask", procedureName: "astp_SJA_DeleteSJATask" });

export { reloadKey, procSJAWorkflowAction, procGetMatrix, procCreateSJA, procDeleteSJA, procAddResponsible, procDeleteTask, procRemoveSignature, procSignSJA, modalInitialMatrix, modalFinalMatrix }